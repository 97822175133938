import { memo } from "react";
import PropTypes from "prop-types";
import "./ProductNameWithCategory.scss";

const ProductNameWithCategory = ({ name, category }) => {
	return (
		<div className="product-name-with-category">
			<span className="product-name-with-category__name" title={name}>
				{name} {category > 0 && `${category}*`}
			</span>
		</div>
	);
};

ProductNameWithCategory.propTypes = {
	name: PropTypes.string,
	category: PropTypes.number,
};

export default memo(ProductNameWithCategory);
