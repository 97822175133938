function IconNegoPlane(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.986 17.648" {...props}>
			<path
				fill="currentColor"
				d="M20.591 3.552c-1.242 1-3.105 2.33-5.222 3.761l.643 9.052-1.834 1.284-3.131-7.5a249.08 249.08 0 01-5.457 3.406l.017.024.176 2.724-1.481 1.039-4.3-6.145 1.477-1.035 2.5 1.1.017.024a237.62 237.62 0 015.066-3.964L3.089 1.813 4.919.529l8.285 3.7c2.079-1.527 3.964-2.818 5.332-3.646 1.036-.625 2.662-.877 3.282-.095.518.841-.276 2.283-1.218 3.042"
			/>
		</svg>
	);
}

export default IconNegoPlane;
