function IconNegoBed(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.693 14.482" {...props}>
			<path
				fill="currentColor"
				d="M17.069 6.978V5.654a1.345 1.345 0 00-1.344-1.344h-3.654c-.516 0-1.118.352-1.118 1.344v1.324z"
				data-name="Trac\xE9 1515"
			/>
			<path
				fill="currentColor"
				d="M9.74 5.655a1.345 1.345 0 00-1.343-1.344H4.968a1.345 1.345 0 00-1.344 1.344v1.324H9.74V5.655z"
				data-name="Trac\xE9 1516"
			/>
			<path
				fill="currentColor"
				d="M2.412 6.979V5.655a2.559 2.559 0 012.556-2.556h3.429a2.55 2.55 0 011.9.846 2.218 2.218 0 011.776-.846h3.655a2.559 2.559 0 012.556 2.556v1.324h1.078V2.166c0-1.861-.3-2.162-2.162-2.162H3.5C1.634 0 1.334.301 1.334 2.166v4.817z"
				data-name="Trac\xE9 1517"
			/>
			<path
				fill="currentColor"
				d="M0 8.191v4.742h1.549v1.549h1.213v-1.549h15.169v1.549h1.212v-1.549h1.549V8.191z"
				data-name="Trac\xE9 1518"
			/>
		</svg>
	);
}

export default IconNegoBed;
