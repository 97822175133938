import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { fetchPushNotifications } from "app/pages/.shared/PushNotification/pushNotificationActionCreators";
import { connect } from "react-redux";
import get from "lodash/get";
import { PushNotification } from "app/pages/.shared/PushNotification/PushNotification";
import { useRouteMatch, useLocation } from "react-router-dom";
import { getPushNotifications } from "app/pages/.shared/PushNotification/pushNotificationSelector";
import { Waypoint } from "react-waypoint";

const PushNotificationComponent = ({ fetchPushNotifications, pushNotifications = [] }) => {
	const match = useRouteMatch();
	const location = useLocation();

	const [hide, setHide] = useState(false);

	useEffect(() => {
		fetchPushNotifications();
	}, []);

	return (
		<>
			<Waypoint
				scrollableAncestor="window"
				bottomOffset={200}
				onEnter={({ previousPosition, currentPosition }) => {
					if (
						previousPosition === Waypoint.below &&
						currentPosition === Waypoint.inside
					) {
						setHide(true);
					}
				}}
				onLeave={({ previousPosition, currentPosition }) => {
					if (
						previousPosition === Waypoint.inside &&
						currentPosition === Waypoint.below
					) {
						setHide(false);
					}
				}}
			/>
			{pushNotifications.map((pushNotification = {}) => {
				const pages = get(pushNotification, "content.pages") || [];
				const checkRoute = pages.find(page => {
					return (
						location.pathname === page ||
						(page === "/:productUri" && match.path === page)
					);
				});
				return (
					checkRoute && (
						<div
							key={pushNotification.name}
							style={{
								transition: "opacity 0.3s ease",
								opacity: hide ? 0 : 1,
							}}
						>
							<PushNotification
								backgroundColor={get(pushNotification, "content.backgroundColor")}
								picto={get(pushNotification, "content.picto")}
								title={get(pushNotification, "content.title")}
								content={get(pushNotification, "content.content")}
								promoCode={get(pushNotification, "content.promoCode")}
								note={get(pushNotification, "content.note")}
								buttonUrl={get(pushNotification, "content.buttonUrl")}
							/>
						</div>
					)
				);
			})}
		</>
	);
};

PushNotificationComponent.propTypes = {
	fetchPushNotifications: PropTypes.func,
	pushNotifications: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
	return {
		pushNotifications: getPushNotifications(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ fetchPushNotifications }, dispatch);
};

export const PushNotificationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(PushNotificationComponent);
