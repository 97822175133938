function IconNegoTransport(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.135 20.664" {...props}>
			<g>
				<path
					fill="currentColor"
					d="M119.583,117.286h0a.391.391,0,0,0,.07-.007l.018,0a.392.392,0,0,0,.065-.02h0a.385.385,0,0,0,.057-.031l.014-.01a.394.394,0,0,0,.05-.041l0,0a.388.388,0,0,0,.043-.052l.01-.015.012-.018,1.852-3.275a2.549,2.549,0,1,0-4.4-.006l1.533,2.711c-1.593.083-5.548.514-6.852,2.725a1.575,1.575,0,0,0-.187,1.348c.378,1.111,2.021,1.772,3.761,2.472,1.264.508,2.7,1.085,3,1.7.019.039.065.129-.043.324-.875,1.589-7.626,3.972-13.061,4.672l3.418-6.043a4.811,4.811,0,1,0-8.294-.006l3.8,6.726a.39.39,0,0,0,.03.046l.007.008a.378.378,0,0,0,.037.04l.014.012a.387.387,0,0,0,.04.03l.012.008a.4.4,0,0,0,.056.028l.016.006.046.013.02,0a.4.4,0,0,0,.062.006h0l.04,0c5.527-.558,13.276-3.051,14.441-5.165a1.093,1.093,0,0,0,.057-1.06c-.441-.881-1.885-1.463-3.414-2.078-1.428-.575-3.047-1.226-3.308-1.993a.78.78,0,0,1,.119-.692C114.124,117.308,119.481,117.286,119.583,117.286Zm0-5.756a1.019,1.019,0,1,1-1.019,1.019A1.02,1.02,0,0,1,119.585,111.529Zm-17.276,9.806a2.5,2.5,0,1,1,2.5,2.5A2.507,2.507,0,0,1,102.309,121.335Z"
					data-name="Trac\xE9 1652"
					transform="translate(-100 -109.967)"
				/>
			</g>
		</svg>
	);
}

export default IconNegoTransport;
