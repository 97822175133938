import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./Timer.scss";
import IconLightning from "app/pages/.shared/IconLightning";

const Timer = ({ hour, day, minute, prefix, suffix }) => {
	const dayNode = day > 0 && (
		<span className="product-time__value">
			<FormattedMessage
				values={{
					day: day,
				}}
				id="timer.day.unit"
			/>
		</span>
	);

	const hourNode = hour > 0 && (
		<span className="product-time__value">
			<FormattedMessage
				values={{
					hour: hour,
				}}
				id="timer.hour.unit"
			/>
		</span>
	);

	const minuteNode = minute > 0 && (
		<span className="product-time__value">
			<FormattedMessage
				values={{
					minute: minute,
				}}
				id="timer.minute.unit"
			/>
		</span>
	);

	return (
		<div className="product-time">
			<IconLightning height={15} width={15} />
			<div className="product-time__time" data-testid="product-time">
				<span className="product-time__prefix">{prefix}</span>
				{dayNode}
				{hourNode}
				{minuteNode}
				{suffix && <span className="product-time__suffix">{suffix}</span>}
			</div>
		</div>
	);
};

Timer.propTypes = {
	day: PropTypes.number,
	hour: PropTypes.number,
	minute: PropTypes.number,
	prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Timer;
