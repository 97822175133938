function IconNegoPlus(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
			<path
				fill="currentColor"
				d="M18.3,12.3V9.8h-5.9V3.7H9.6v6.1H3.7v2.5h5.9v6h2.8v-6L18.3,12.3z"
			/>
		</svg>
	);
}

export default IconNegoPlus;
