import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import LazyLoad from "react-lazyload";
import classNames from "classnames";
import { connect } from "react-redux";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";

import "./progressive-image.scss";

const MAX_WIDTHS = {
	small: 768,
	medium: 978,
	large: 1440,
	xlarge: 1440,
};

const IMG_STYLES = {
	width: "100%",
	display: "block",
	height: "100%",
};

const IMG_WRAPPER_STYLES = {
	position: "relative",
	height: "100%",
	width: "100%",
};

const ProgressiveImg = ({
	sizes,
	alt,
	src,
	resolution,
	thumbnail,
	offset,
	onLoad,
	className,
	quality,
	cloudinaryOptions,
}) => {
	const [loaded, setLoaded] = useState(false);

	const cloudinarify = useCallback(() => {
		let cloudinaryConfig = [];

		const size = sizes && sizes[resolution];

		if (size && size.width) {
			cloudinaryConfig.push(`w_${size.width}`);
		} else {
			cloudinaryConfig.push(`w_${MAX_WIDTHS[resolution]}`);
		}

		if (size && size.height) {
			cloudinaryConfig.push(`h_${size.height},c_fill,g_center`);
		}
		if (src) {
			return addOptionsToCloundinaryURL(src, cloudinaryConfig.concat(cloudinaryOptions), {
				quality,
			});
		}

		return "";
	}, [resolution, src, quality, sizes]);

	const onImageLoad = useCallback(() => {
		setLoaded(true);
		if (typeof onLoad === "function") {
			onLoad();
		}
	}, []);

	const imgClassname = classNames({
		"progressive-image": true,
		"progressive-image--revealed": loaded,
	});

	const thumbnailNode = thumbnail ? (
		<img style={IMG_STYLES} alt={alt} src={`data:image/jpeg;base64,${thumbnail}`} />
	) : (
		false
	);

	const source = cloudinarify();

	return (
		<div style={IMG_WRAPPER_STYLES} className={className}>
			{thumbnailNode}
			{resolution !== RESOLUTION.UNKNOWN && sizes[resolution] !== undefined ? (
				<LazyLoad once offset={offset}>
					<img
						style={IMG_STYLES}
						alt={alt}
						className={imgClassname}
						src={source}
						onLoad={onImageLoad}
					/>
				</LazyLoad>
			) : (
				false
			)}
		</div>
	);
};

ProgressiveImg.propTypes = {
	sizes: PropTypes.shape({
		small: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		medium: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		large: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		xlarge: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
	}),
	alt: PropTypes.string,
	src: PropTypes.string,
	resolution: PropTypes.string,
	thumbnail: PropTypes.string,
	offset: PropTypes.number,
	onLoad: PropTypes.func,
	className: PropTypes.string,
	quality: PropTypes.string,
	cloudinaryOptions: PropTypes.array,
};

ProgressiveImg.defaultProps = {
	sizes: {},
	offset: 800,
	cloudinaryOptions: [],
};

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(ProgressiveImg);
