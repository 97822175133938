import { Component } from "react";
import PropTypes from "prop-types";
import Collapse, { Panel } from "rc-collapse";
import "./MoreDetails.scss";
import { connect } from "react-redux";

class MoreDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: props.initialExpanded || props.expanded || false,
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange(event) {
		if (typeof event.preventDefault === "function") {
			event.preventDefault();
		}
		const isExpanded = this.state.expanded;
		this.setState({
			expanded: !isExpanded,
		});

		if (typeof this.props.onChange === "function") {
			this.props.onChange(!isExpanded);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.expanded !== undefined) {
			this.setState({
				expanded: nextProps.expanded,
			});
		}
	}

	render() {
		const {
			children,
			collapsedLabel,
			expandedLabel,
			headerPosition = "top",
			isPrintMode,
		} = this.props;

		const activeKey = this.state.expanded || isPrintMode ? "0" : undefined;
		const headerNode = this.state.expanded || isPrintMode ? expandedLabel : collapsedLabel;

		const topHeaderNode = headerPosition === "top" ? headerNode : false;

		const bottomHeaderNode = headerPosition === "bottom" && (
			<div className="rc-collapse-header" onClick={this.onChange}>
				{headerNode}
			</div>
		);

		return (
			<div className="more-details">
				<Collapse onChange={this.onChange} activeKey={activeKey}>
					<Panel header={topHeaderNode}>{children}</Panel>
				</Collapse>
				{bottomHeaderNode}
			</div>
		);
	}
}

MoreDetails.propTypes = {
	expanded: PropTypes.bool,
	initialExpanded: PropTypes.bool,
	isPrintMode: PropTypes.bool,
	collapsedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	expandedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	headerPosition: PropTypes.oneOf(["top", "bottom"]),
	onChange: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		isPrintMode: state.print.isPrintMode,
	};
};

export default connect(mapStateToProps)(MoreDetails);
