import { memo, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { messagePropType } from "app/utils/propTypes";
import "./LineClampViewMore.scss";

const LineClampViewMore = ({ enableShowMore, text, lineCount }) => {
	const [isLineClamped, updateLineClamped] = useState(false);
	const [isViewMore, updateIsViewMore] = useState(false);
	const ref = useRef();

	useLayoutEffect(() => {
		if (ref.current) {
			// @see https://stackoverflow.com/questions/52232026/determine-if-ellipsis-is-being-displayed-when-using-webkit-line-clamp-for-multi/52650163
			updateLineClamped(ref.current.offsetHeight < ref.current.scrollHeight === true);
		}
	}, [ref, text]);

	const lineClampClass = classNames(
		{
			"line-clamp-view-more--show-all": isViewMore,
		},
		{
			"line-clamp-view-more--mutiline": !isViewMore && lineCount > 0,
		}
	);

	const onButtonClick = value => {
		updateIsViewMore(value);
	};

	return (
		<div className="line-clamp-view-more">
			<span
				className={lineClampClass}
				ref={ref}
				style={{
					WebkitLineClamp: lineCount,
				}}
			>
				{text}
				{isViewMore && (
					<span
						onClick={() => onButtonClick(false)}
						className="line-clamp-view-more__reduce"
					>
						<>
							<a data-testid="read-more-button">
								<FormattedMessage id="general.reduce" />
							</a>
						</>
					</span>
				)}
			</span>
			{enableShowMore && isLineClamped && !isViewMore && (
				<div>
					<span
						onClick={() => onButtonClick(true)}
						className="line-clamp-view-more__view-more"
					>
						<>
							…
							<a data-testid="read-more-button">
								<FormattedMessage id="general.read.more" />
							</a>
						</>
					</span>
				</div>
			)}
		</div>
	);
};

LineClampViewMore.propTypes = {
	lineCount: PropTypes.number,
	text: messagePropType,
	enableShowMore: PropTypes.bool,
};

export default memo(LineClampViewMore);
