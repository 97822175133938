import { CLOSE_SLIDESHOW, OPEN_SLIDESHOW } from "../../../actionTypes";

export const closeSlideshow = () => {
	return {
		type: CLOSE_SLIDESHOW,
	};
};

export const openSlideshow = photosType => {
	return {
		type: OPEN_SLIDESHOW,
		photosType,
	};
};
